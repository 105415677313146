import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [LoadingSpinnerComponent],
  imports: [CommonModule, FlexLayoutModule, MatProgressSpinnerModule],
  exports: [LoadingSpinnerComponent],
})
export class LoadingModule {}
