import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Membership, WhoAmI } from '@clients/api';

@Component({
  selector: 'clients-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserComponent {
  @Input() user: firebase.User | null = null;
  @Input() whoAmI?: WhoAmI;
  @Input() availableLangs: string[] = [];
  @Input() currentLang?: string;
  @Input() membership?: Membership;

  @Output() changeLang: EventEmitter<string> = new EventEmitter<string>();

  constructor(private router: Router, private firebaseAuth: AngularFireAuth) {}

  logout() {
    this.firebaseAuth.signOut().then(() => {
      this.router.navigate(['./home'], { queryParamsHandling: 'merge' });
    });
  }
}
