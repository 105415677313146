import { NgModule } from '@angular/core';
import { LoadingButtonDirective } from './loading-button.directive';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { ConfirmButtonDirective } from './confirm-button.directive';

@NgModule({
  declarations: [LoadingButtonDirective, ConfirmButtonDirective],
  exports: [LoadingButtonDirective, ConfirmButtonDirective],
  imports: [MatButtonModule, MatProgressBarModule],
})
export class ButtonModule {}
