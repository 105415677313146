<ng-container *transloco="let t">
  <div fxLayout fxLayoutGap="8px" fxLayoutAlign="start center">
    <button
      mat-raised-button
      color="accent"
      *ngIf="membership?.coupon"
      [routerLink]="['/', 'membership']"
      queryParamsHandling="merge"
      fxHide.lt-md
    >
      {{
        t('app.toolbar.balance', {
          balance: membership?.balance | currency: 'EUR'
        })
      }}
    </button>

    <a
      mat-raised-button
      href="{{ t('app.toolbar.help_url') }}"
      target="_blank"
      color="accent"
      fxHide.lt-md
    >
      {{ t('app.toolbar.help_msg') }}
    </a>
    <a
      mat-icon-button
      href="{{ t('app.toolbar.help_url') }}"
      target="_blank"
      fxHide.gt-sm
    >
      <mat-icon>help_outline</mat-icon>
    </a>

    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>

  <mat-menu #menu="matMenu">
    <ng-container *ngIf="!user; else userInfo">
      <div
        mat-menu-item
        [disableRipple]="true"
        fxLayout
        fxLayoutAlign="space-evenly center"
        class="lang-select"
      >
        <shared-language-select
          [available]="availableLangs"
          [selected]="currentLang"
          (selectionChanged)="changeLang.emit($event)"
        ></shared-language-select>
      </div>

      <button
        mat-menu-item
        color="secondary"
        [routerLink]="['auth']"
        [routerLinkActive]="['hidden']"
        queryParamsHandling="merge"
      >
        {{ t('app.user.login') }}
      </button>
    </ng-container>

    <ng-template #userInfo>
      <small>{{ t('app.user.logged-in-as') }}</small>

      <div mat-menu-item>
        <mat-icon>person</mat-icon>
        <span>{{ whoAmI?.email }}</span>
      </div>

      <button
        mat-menu-item
        *ngIf="membership?.coupon"
        [routerLink]="['/', 'membership']"
        queryParamsHandling="merge"
      >
        <mat-icon>info</mat-icon>
        <span>{{ t('app.membership.title') }}</span>
      </button>

      <button
        mat-menu-item
        [routerLink]="['/', 'guest-notes']"
        queryParamsHandling="merge"
      >
        <mat-icon>sticky_note_2</mat-icon>
        <span>{{ t('app.guest-notes.title') }}</span>
      </button>

      <button mat-menu-item *ngIf="user" (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>{{ t('app.user.logout') }}</span>
      </button>
    </ng-template>
  </mat-menu>
</ng-container>
