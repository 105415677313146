import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, EMPTY, timer } from 'rxjs';
import { catchError, mergeMap, retryWhen } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslationService } from '@clients/helper';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable()
export class ErrorSnackbarInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private firebaseAuth: AngularFireAuth,
    private translationService: TranslationService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.startsWith('/assets/i18n')) {
      // Hide error snack bar on missing translations
      return next.handle(req);
    }

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Blob) {
          return this.blobToJson(err.error).pipe(
            mergeMap((newError: {}) => {
              const error = { ...err, error: newError };
              return this.handleError(error);
            })
          );
        }

        return this.handleError(err);
      })
    );
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    switch (err.status) {
      // Offline
      case 0:
      // Timeout
      case 504: {
        return throwError(err);
      }
      case 401: {
        this.firebaseAuth.signOut().then(() => {
          this.router.navigate(['./auth'], { queryParamsHandling: 'merge' });
        });
        this.handleCustomError(err);
        return EMPTY;
      }
      case 404: {
        return throwError(err);
      }
      default: {
        this.handleCustomError(err);
      }
    }

    return throwError(err);
  }

  private handleCustomError(err: HttpErrorResponse) {
    if (err.error && err.error.key && err.error.user_msg && err.error.data) {
      if (err.error.key === 'ACCOUNT_INFO_MISSING_EXCEPTION') {
        this.showWarnSnack(err.error.user_msg, true);
        return;
      }

      this.showWarnSnack(err.error.user_msg);
    } else {
      this.showWarnSnack(
        this.translationService.translate('app.general.internal-error')
      );
    }
  }

  private showWarnSnack(message: string, isWarning = false) {
    this.snackBar.open(
      message,
      this.translationService.translate('app.general.close'),
      {
        duration: 15000,
        panelClass: isWarning ? 'warning-snack' : 'error-snack',
      }
    );
  }

  private blobToJson(blob: Blob): Observable<{}> {
    return new Observable((obs) => {
      const reader = new FileReader();

      reader.onerror = (err) => obs.error(err);
      reader.onabort = (err) => obs.error(err);
      reader.onload = () => {
        try {
          obs.next(JSON.parse(reader.result as string));
        } catch {
          obs.next({});
        }
      };
      reader.onloadend = () => obs.complete();

      return reader.readAsText(blob);
    });
  }
}
