import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'clients-new-template-card',
  templateUrl: './new-template-card.component.html',
  styleUrls: ['./new-template-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewTemplateCardComponent {
  @Output() new: EventEmitter<void> = new EventEmitter<void>();
}
