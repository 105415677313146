import { NgModule, ModuleWithProviders } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { FirebaseEnvironment } from './firebase-environment';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
  UserTrackingService,
  APP_VERSION,
  DEBUG_MODE,
} from '@angular/fire/analytics';
import { VersionEnvironment } from '../version/version-environment';

@NgModule({
  imports: [
    HttpClientModule,
    AngularFireModule,
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    AngularFirePerformanceModule,
    AngularFireAnalyticsModule,
  ],
})
export class FirebaseModule {
  static forRoot(
    environment: FirebaseEnvironment &
      VersionEnvironment & { production: boolean }
  ): ModuleWithProviders<FirebaseModule> {
    return {
      ngModule: FirebaseModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
        // insted of calling AngularFireModule.initializeApp in imports
        // https://github.com/angular/angularfire2/blob/master/src/core/firebase.app.module.ts
        { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
        ScreenTrackingService,
        UserTrackingService,
        { provide: DEBUG_MODE, useValue: !environment.production },
        { provide: APP_VERSION, useValue: environment.version },
      ],
    };
  }
}
