<mat-card *transloco="let t">
  <mat-card-content
    fxLayout
    fxLayout.xs="column"
    fxLayoutAlign="start center"
    fxLayoutAlign.xs="start start"
  >
    <div fxLayout fxLayoutAlign="start center">
      <mat-icon>warning</mat-icon><span>&nbsp;</span>
      <strong>{{ t('app.license.notification') }}:</strong>
      <span>&nbsp;{{ t('app.license.expires-soon') }}</span>
    </div>

    <div fxFlex="1 1 auto"></div>

    <div fxLayout fxLayoutAlign="start center">
      <a
        mat-raised-button
        href="{{ t('app.license.url') }}"
        target="_blank"
        color="accent"
      >
        {{ t('app.license.request-abo') }}
      </a>
    </div>
  </mat-card-content>
</mat-card>
