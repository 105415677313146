import { NgModule, ModuleWithProviders } from '@angular/core';
import {
  TranslocoModule,
  TRANSLOCO_SCOPE,
  TRANSLOCO_CONFIG,
} from '@ngneat/transloco';
import { translocoLoader } from './transloco.loader';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserLanguageInterceptor } from './user-language.interceptor';
import { ENVIRONMENT } from '../environment';
import { configurationFactory } from './configuration-factory';

@NgModule({
  imports: [TranslocoModule],
  exports: [TranslocoModule],
})
export class TranslationModule {
  static forRoot(): ModuleWithProviders<TranslationModule> {
    return {
      ngModule: TranslationModule,
      providers: [
        {
          provide: TRANSLOCO_CONFIG,
          useFactory: configurationFactory,
          deps: [ENVIRONMENT],
        },
        translocoLoader,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: UserLanguageInterceptor,
          multi: true,
        },
      ],
    };
  }

  static forChild(scopeName: string): ModuleWithProviders<TranslationModule> {
    return {
      ngModule: TranslationModule,
      providers: [{ provide: TRANSLOCO_SCOPE, useValue: scopeName }],
    };
  }
}
