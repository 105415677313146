import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Configuration } from './auto-gen';
import { configurationFactory } from './api/configuration-factory';
import { ENVIRONMENT } from '@clients/helper';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ErrorSnackbarInterceptor } from './api/error-snackbar.interceptor';

@NgModule({
  imports: [HttpClientModule, MatSnackBarModule],
})
export class ApiModule {
  public static forRoot(): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: Configuration,
          useFactory: configurationFactory,
          deps: [ENVIRONMENT],
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ErrorSnackbarInterceptor,
          multi: true,
        },
      ],
    };
  }
}
