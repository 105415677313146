/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface VarRead {
  default_value?: string;
  is_optional: boolean;
  is_prefilled?: boolean;
  is_radio_template: boolean;
  key: string;
  language: string;
  name: string;
  sub_vars?: Array<VarRead>;
  type: VarRead.TypeEnum;
  type_template_id?: number;
}
export namespace VarRead {
  export type TypeEnum = 'template' | 'text' | 'date' | 'image';
  export const TypeEnum = {
    Template: 'template' as TypeEnum,
    Text: 'text' as TypeEnum,
    Date: 'date' as TypeEnum,
    Image: 'image' as TypeEnum,
  };
}
