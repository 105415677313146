import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  user$: Observable<firebase.User | null>;

  constructor(
    private angularFireAuth: AngularFireAuth,
    private analytics: AngularFireAnalytics
  ) {
    this.user$ = this.angularFireAuth.user.pipe(
      map((user: firebase.User | null) =>
        !!user && user.emailVerified ? user : null
      )
    );
  }

  logEvent(eventName: string, eventParams?: { [key: string]: any }) {
    this.analytics.logEvent(eventName, eventParams);
  }
}
