import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  FirebaseService,
  TranslationService,
  ToolbarService,
} from '@clients/helper';
import {
  MySettingsControllerService,
  MySettings,
  MembershipService,
  Membership,
  WhoAmIService,
  WhoAmI,
} from '@clients/api';
import { take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Event, NavigationEnd, Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'clients-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  availableLangs: string[];
  currentLang: string;
  user: firebase.User | null = null;
  whoAmI?: WhoAmI;
  title: string | null = null;
  returnRoute?: string[];
  membership?: Membership;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private firebaseService: FirebaseService,
    private translationService: TranslationService,
    private mySettingsControllerService: MySettingsControllerService,
    private toolbarService: ToolbarService,
    private membershipService: MembershipService,
    private whoAmIService: WhoAmIService,
    private router: Router
  ) {
    this.availableLangs = this.translationService.getAvailableLangs();
    this.currentLang = this.translationService.getActiveLang();
  }

  ngOnInit() {
    this.toolbarService.title$
      .pipe(untilDestroyed(this))
      .subscribe((title: string | null) => {
        this.title = title;
        this.changeDetectorRef.detectChanges();
      });
    this.toolbarService.returnRoute$
      .pipe(untilDestroyed(this))
      .subscribe((returnRoute: string[]) => {
        this.returnRoute = returnRoute;
        this.changeDetectorRef.detectChanges();
      });

    this.router.events.pipe(untilDestroyed(this)).subscribe((e: Event) => {
      if (e instanceof NavigationEnd) {
        this.updateUserInfo();
      }
    });

    this.whoAmIService.whoAmI$
      .pipe(untilDestroyed(this))
      .subscribe((whoAmi: WhoAmI | undefined) => {
        this.whoAmI = whoAmi;
      });

    this.firebaseService.user$
      .pipe(untilDestroyed(this))
      .subscribe((user: firebase.User | null) => {
        this.user = user;

        this.updateUserInfo();
      });

    // only on first try since otherwise the language already gets set during login
    this.firebaseService.user$
      .pipe(take(1))
      .subscribe((user: firebase.User | null) => {
        if (!this.user) {
          return;
        }

        this.mySettingsControllerService
          .getMySettingsUsingGET({})
          .subscribe((settings: MySettings) => {
            if (settings.language) {
              this.translationService.setActiveLang(settings.language);
            }
          });
      });

    this.membershipService.membership$.subscribe(
      (membership?: Membership) => (this.membership = membership)
    );
  }

  private updateUserInfo() {
    if (this.router.url.startsWith('/auth')) {
      return;
    }

    if (this.user) {
      this.membershipService.refresh();
      this.whoAmIService.refresh();
    } else {
      this.membershipService.reset();
      this.whoAmIService.reset();
    }
  }

  changeLang(lang: string) {
    this.translationService.setActiveLang(lang);
    this.currentLang = lang;
  }
}
