import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl,
  SafeScript,
  SafeStyle,
  SafeUrl,
  SafeValue,
} from '@angular/platform-browser';
import { NgDompurifyConfig } from '@tinkoff/ng-dompurify/lib/types/ng-dompurify-config';
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';
import { SecurityContext } from '@angular/core';

export class QuillDomSanitizer extends DomSanitizer {
  private readonly config: NgDompurifyConfig;

  constructor(private sanitizer: NgDompurifySanitizer) {
    super();
    this.config = {};
  }

  bypassSecurityTrustHtml(value: string): SafeHtml {
    return this.sanitizer.sanitize(SecurityContext.HTML, value, this.config);
  }

  bypassSecurityTrustResourceUrl(value: string): SafeResourceUrl {
    return this.sanitizer.sanitize(
      SecurityContext.RESOURCE_URL,
      value,
      this.config
    );
  }

  bypassSecurityTrustScript(value: string): SafeScript {
    return this.sanitizer.sanitize(SecurityContext.SCRIPT, value, this.config);
  }

  bypassSecurityTrustStyle(value: string): SafeStyle {
    return this.sanitizer.sanitize(SecurityContext.STYLE, value, this.config);
  }

  bypassSecurityTrustUrl(value: string): SafeUrl {
    return this.sanitizer.sanitize(SecurityContext.URL, value, this.config);
  }

  sanitize(
    context: SecurityContext,
    value: SafeValue | string | null
  ): string | null;
  // tslint:disable-next-line:unified-signatures
  sanitize(context: SecurityContext, value: {} | string | null): string | null;
  sanitize(
    context: SecurityContext,
    value: SafeValue | string | null | {}
  ): string | null {
    return this.sanitizer.sanitize(context, value, this.config);
  }
}
