<form [formGroup]="form" (ngSubmit)="submit()" *transloco="let t">
  <h1 mat-dialog-title>{{ t('shared.quill.wizard.new-template') }}</h1>

  <div mat-dialog-content>
    <mat-form-field fxFlex="1 1 auto">
      <input
        matInput
        [placeholder]="t('edit.name')"
        formControlName="name"
        required
      />
      <mat-error *ngIf="form.controls['name'].hasError('required')">
        <strong>{{ t('app.general.mandatory') }}</strong>
      </mat-error>
      <mat-error *ngIf="form.controls['name'].hasError('pattern')">
        <strong>{{ t('edit.invalid-name-pattern') }}</strong>
      </mat-error>
    </mat-form-field>
  </div>

  <div mat-dialog-actions>
    <div fxFlex="1 1 auto"></div>
    <button mat-raised-button [mat-dialog-close]="null">
      {{ t('app.general.cancel') }}
    </button>
    <button mat-raised-button color="accent" type="submit">
      {{ t('app.general.save') }}
    </button>
  </div>
</form>
