import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToolbarService {
  readonly DEFAULT_RETURN_ROUTE: string[] = ['home'];

  title$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(
    null
  );
  returnRoute$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(
    this.DEFAULT_RETURN_ROUTE
  );

  setTitle(title: string) {
    this.title$.next(title);
  }

  clearTitle() {
    this.title$.next(null);
  }

  setReturnRoute(route: string[]) {
    this.returnRoute$.next(route);
  }

  resetReturnRoute() {
    this.returnRoute$.next(this.DEFAULT_RETURN_ROUTE);
  }
}
