import { Injectable } from '@angular/core';
import { HashMap, TranslocoScope, TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  constructor(private translocoService: TranslocoService) {}

  translate(key: string, params?: HashMap): string {
    return this.translocoService.translate(key, params);
  }

  selectTranslate(
    key: string,
    params?: HashMap,
    scope?: TranslocoScope
  ): Observable<string> {
    return this.translocoService.selectTranslate(key, params, scope);
  }

  getAvailableLangs(): string[] {
    return this.translocoService.getAvailableLangs() as string[];
  }

  getActiveLang(): string {
    return this.translocoService.getActiveLang();
  }

  setActiveLang(lang: string) {
    this.translocoService.setActiveLang(lang);
  }

  getOnLangChanges(): Observable<string> {
    return this.translocoService.langChanges$;
  }
}
