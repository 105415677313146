export * from './generationController.service';
import { GenerationControllerService } from './generationController.service';
export * from './generationController.serviceInterface';
export * from './guestNoteController.service';
import { GuestNoteControllerService } from './guestNoteController.service';
export * from './guestNoteController.serviceInterface';
export * from './healthController.service';
import { HealthControllerService } from './healthController.service';
export * from './healthController.serviceInterface';
export * from './htmlUpdateController.service';
import { HtmlUpdateControllerService } from './htmlUpdateController.service';
export * from './htmlUpdateController.serviceInterface';
export * from './imageController.service';
import { ImageControllerService } from './imageController.service';
export * from './imageController.serviceInterface';
export * from './languageController.service';
import { LanguageControllerService } from './languageController.service';
export * from './languageController.serviceInterface';
export * from './loginController.service';
import { LoginControllerService } from './loginController.service';
export * from './loginController.serviceInterface';
export * from './mySettingsController.service';
import { MySettingsControllerService } from './mySettingsController.service';
export * from './mySettingsController.serviceInterface';
export * from './systemController.service';
import { SystemControllerService } from './systemController.service';
export * from './systemController.serviceInterface';
export * from './templateCategoryController.service';
import { TemplateCategoryControllerService } from './templateCategoryController.service';
export * from './templateCategoryController.serviceInterface';
export * from './templateController.service';
import { TemplateControllerService } from './templateController.service';
export * from './templateController.serviceInterface';
export * from './textController.service';
import { TextControllerService } from './textController.service';
export * from './textController.serviceInterface';
export * from './userController.service';
import { UserControllerService } from './userController.service';
export * from './userController.serviceInterface';
export * from './varController.service';
import { VarControllerService } from './varController.service';
export * from './varController.serviceInterface';
export const APIS = [
  GenerationControllerService,
  GuestNoteControllerService,
  HealthControllerService,
  HtmlUpdateControllerService,
  ImageControllerService,
  LanguageControllerService,
  LoginControllerService,
  MySettingsControllerService,
  SystemControllerService,
  TemplateCategoryControllerService,
  TemplateControllerService,
  TextControllerService,
  UserControllerService,
  VarControllerService,
];
