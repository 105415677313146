import { Component, OnInit } from '@angular/core';
import {
  TemplateListRead,
  TemplateService,
  License,
  MySettingsControllerService,
  TemplatePosition,
  TemplateControllerService,
  TemplateCategory,
  TemplateCategoryControllerService,
  Membership,
  MembershipService,
} from '@clients/api';
import { FirebaseService, TranslationService } from '@clients/helper';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoginRequiredComponent } from './login-required/login-required.component';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'clients-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  private readonly CATEGORY_ID_QUERY_PARAM = 'category_id';

  user: firebase.User | null = null;
  favorites: TemplateListRead[] = [];
  noneFavorites: TemplateListRead[] = [];
  templateCategories: TemplateCategory[] = [];
  templateCategoryControl?: FormControl;
  license?: License;
  membership?: Membership;
  loading = true;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private matDialog: MatDialog,
    private firebaseService: FirebaseService,
    private formBuilder: FormBuilder,
    private templateService: TemplateService,
    private translationService: TranslationService,
    private mySettingsControllerService: MySettingsControllerService,
    private templateControllerService: TemplateControllerService,
    private templateCategoryControllerService: TemplateCategoryControllerService,
    private membershipService: MembershipService
  ) {}

  ngOnInit() {
    this.firebaseService.user$
      .pipe(untilDestroyed(this))
      .subscribe((user: firebase.User | null) => {
        this.user = user;

        this.membershipService.membership$.subscribe(
          (membership?: Membership) => (this.membership = membership)
        );

        this.license = undefined;
        if (this.user) {
          this.translationService
            .getOnLangChanges()
            .pipe(untilDestroyed(this))
            .subscribe(() => this.getTemplateList());
          this.mySettingsControllerService
            .getLicenseUsingGET({})
            .subscribe((license: License) => (this.license = license));
        } else {
          this.translationService
            .getOnLangChanges()
            .pipe(untilDestroyed(this))
            .subscribe(() => this.getTemplateCategoryList());
        }
      });
  }

  private getTemplateCategoryList() {
    this.templateCategoryControllerService
      .getTemplateCatgoriesUsingGET({})
      .subscribe((templateCategories: TemplateCategory[]) => {
        if (!templateCategories || templateCategories.length === 0) {
          console.error('No template categories found');
          return;
        }

        this.templateCategories = templateCategories;
        const queryParam:
          | string
          | null = this.activatedRoute.snapshot.queryParamMap.get(
          this.CATEGORY_ID_QUERY_PARAM
        );
        const paramId: number | null =
          queryParam && Number.isInteger(+queryParam) ? +queryParam : null;
        this.templateCategoryControl = this.formBuilder.control(
          this.templateCategories.findIndex(
            (category: TemplateCategory) =>
              category.templatecategory_id === paramId
          ) >= 0
            ? paramId
            : this.templateCategories[0].templatecategory_id
        );

        this.getTemplateList();
      });
  }

  generateTemplate(templateId?: number) {
    this.router.navigate(['./generate', templateId], {
      queryParamsHandling: 'merge',
    });
  }

  newTemplate(favorite: boolean) {
    this.navigateIfUser(['./template', 'new'], { favorite });
  }

  editTemplate(templateId: number) {
    this.navigateIfUser(['./template', 'edit', `${templateId}`]);
  }

  updatePositions(templatePositions: TemplatePosition[]) {
    this.templateControllerService
      .updateTemplatePositionUsingPUT({
        templatePositions,
      })
      .subscribe();
  }

  private getTemplateList() {
    if (!this.user && !this.templateCategoryControl) {
      return;
    }
    this.updateParams();

    this.loading = true;
    this.templateService
      .getList(
        this.templateCategoryControl
          ? this.templateCategoryControl.value
          : undefined
      )
      .subscribe((templates: TemplateListRead[]) => {
        this.favorites = templates.filter(
          (template: TemplateListRead) => !!template.is_favorite
        );
        this.noneFavorites = templates.filter(
          (template: TemplateListRead) => !template.is_favorite
        );
        this.loading = false;
      });
  }

  private updateParams() {
    const queryParams: { [key: string]: string } = {};
    queryParams[this.CATEGORY_ID_QUERY_PARAM] = this.templateCategoryControl
      ? this.templateCategoryControl.value
      : undefined;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  private navigateIfUser(path: string[], queryParams?: Params) {
    if (this.user) {
      this.router.navigate(path, {
        queryParams,
        queryParamsHandling: 'merge',
      });
    } else {
      const dialogRef: MatDialogRef<LoginRequiredComponent> = this.matDialog.open(
        LoginRequiredComponent
      );
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.router.navigate(['./auth'], { queryParamsHandling: 'merge' });
        }
      });
    }
  }
}
