import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'shared-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectComponent {
  @Input() available: string[] = [];
  @Input() selected?: string;

  @Output() selectionChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitzer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'de',
      this.domSanitzer.bypassSecurityTrustResourceUrl(
        '/assets/flags/germany.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'it',
      this.domSanitzer.bypassSecurityTrustResourceUrl('/assets/flags/italy.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'en',
      this.domSanitzer.bypassSecurityTrustResourceUrl(
        '/assets/flags/united-states.svg'
      )
    );
  }
}
