import { NgModule, ModuleWithProviders } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { VersionInterceptor } from './version.interceptor';

@NgModule({})
export class VersionModule {
  public static forRoot(): ModuleWithProviders<VersionModule> {
    return {
      ngModule: VersionModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: VersionInterceptor,
          multi: true,
        },
      ],
    };
  }
}
