import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivateChild,
  CanActivate,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { WhoAmI } from './auto-gen';
import { WhoAmIService } from './services/who-am-i.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslationService } from '@clients/helper';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private snackbar: MatSnackBar,
    private whoAmIService: WhoAmIService,
    private translationService: TranslationService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.activateIfAdmin(next, state);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.activateIfAdmin(next, state);
  }

  private activateIfAdmin(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const adminGuardRedirect: string[] = next.data.adminGuardRedirect ?? [];
    return this.whoAmIService.whoAmI$.pipe(
      filter((whoAmi: WhoAmI | undefined) => !!whoAmi),
      take(1),
      map((whoAmi: WhoAmI | undefined) => {
        // assuming only an admin can have different user_id and original_user_id
        // assumption required to not lock out an admin of the admin interface if signed_in_as is set
        // if signed_in_as is set the is_su field is set to what ever the signed in as user is
        if (whoAmi?.is_su || whoAmi?.user_id !== whoAmi?.original_user_id) {
          return true;
        } else {
          this.snackbar.open(
            this.translationService.translate('app.general.permission-denied'),
            this.translationService.translate('app.general.close'),
            {
              duration: 15000,
            }
          );
          return this.router.createUrlTree(adminGuardRedirect);
        }
      })
    );
  }
}
