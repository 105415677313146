<form [formGroup]="form" (ngSubmit)="submit()" *transloco="let t">
  <div mat-dialog-content fxLayout="column" fxLayoutGap="8px">
    <mat-form-field *ngIf="!data.whoAmI.is_template_user">
      <input
        matInput
        [placeholder]="t('shared.quill.wizard.name')"
        formControlName="name"
        required
      />
      <mat-error *ngIf="form.controls['name'].hasError('required')">
        {{ t('app.general.mandatory') }}
      </mat-error>
      <mat-error *ngIf="form.controls['name'].hasError('pattern')">
        <strong>{{ t('shared.quill.wizard.invalid-name-pattern') }}</strong>
      </mat-error>
    </mat-form-field>

    <div
      *ngIf="data.whoAmI.is_template_user"
      class="localized-names"
      formArrayName="localized_names"
      fxLayout="column"
      fxLayoutGap="8px"
    >
      <div
        fxLayout
        fxLayoutGap="16px"
        *ngFor="
          let locals of localizedNamesControls.controls;
          let localIndex = index
        "
        [formGroupName]="localIndex"
      >
        <mat-form-field class="localized-language">
          <input matInput formControlName="language" readonly />
        </mat-form-field>
        <mat-form-field class="full-width">
          <input
            matInput
            [placeholder]="t('shared.quill.wizard.name')"
            formControlName="text"
          />
          <mat-error
            *ngIf="
              localizedNamesControls.controls[localIndex].controls[
                'text'
              ].hasError('pattern')
            "
          >
            <strong>{{ t('shared.quill.wizard.invalid-name-pattern') }}</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <mat-error *ngIf="localizationRequired">
        {{ t('shared.quill.wizard.mandatory-localization') }}
      </mat-error>
    </div>

    <div
      *ngIf="form.controls['template_id']"
      fxLayout
      fxLayoutAlign="start center"
      fxLayoutGap="8px"
    >
      <mat-form-field fxFlex="1 1 auto">
        <mat-label>
          {{
            t(
              'shared.quill.wizard.type.' +
                (data.current ? 'used-template' : 'select-template')
            )
          }}
        </mat-label>
        <input
          matInput
          formControlName="template_id"
          [matAutocomplete]="auto"
        />
        <mat-error *ngIf="form.controls['template_id'].hasError('required')">
          {{ t('app.general.mandatory') }}
        </mat-error>
        <mat-error
          *ngIf="
            !form.controls['template_id'].hasError('required') &&
            form.controls['template_id'].hasError('invalidSelection')
          "
        >
          {{ t('shared.quill.wizard.invalid-template') }}
        </mat-error>

        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayFn"
          (closed)="autocompleteClosed()"
        >
          <mat-option (onSelectionChange)="newTemplate($event)" value="">
            {{ t('shared.quill.wizard.new-template') }}
          </mat-option>
          <mat-divider></mat-divider>

          <mat-option
            *ngFor="let template of filteredTemplates | async"
            [value]="template.template_id"
          >
            {{ template.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <button
        mat-raised-button
        color="accent"
        type="button"
        [ngClass]="{ invisible: !form.controls['template_id'].value }"
        (click)="data.openTemplate.emit(form.controls['template_id'].value)"
      >
        <mat-icon>edit</mat-icon>
        {{ t('shared.quill.wizard.edit-used-template') }}
      </button>
    </div>

    <div class="checkbox-wrapper" fxFlex fxLayoutGap="8px">
      <mat-checkbox formControlName="is_optional">
        {{ t('shared.quill.wizard.mandatory-input') }}
      </mat-checkbox>
      <mat-checkbox formControlName="is_prefilled">
        {{ t('shared.quill.wizard.prefill') }}
      </mat-checkbox>
    </div>
  </div>

  <div mat-dialog-actions fxLayout fxLayoutGap="8px" class="button-wrapper">
    <button
      mat-raised-button
      [mat-dialog-close]="'delete'"
      color="warn"
      *ngIf="data.current"
    >
      {{ t('app.general.delete') }}
    </button>
    <div fxFlex="1 1 auto"></div>
    <button mat-raised-button [mat-dialog-close]="null">
      {{ t('app.general.cancel') }}
    </button>
    <button mat-raised-button color="accent" type="submit">
      {{
        t(
          data.current
            ? 'app.general.save'
            : 'shared.quill.insert.' + form.controls['type'].value
        )
      }}
    </button>
  </div>
</form>
