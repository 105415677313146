import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[helperAutofocus]',
})
export class AutofocusDirective implements OnInit {
  @Input() helperAutofocus = true;

  constructor(private ref: ElementRef) {}

  ngOnInit() {
    if (this.helperAutofocus) {
      setTimeout(() => this.ref.nativeElement.select(), 0);
    }
  }
}
