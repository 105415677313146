import { Route } from '@angular/router';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { HomeComponent } from './home/home.component';
import {
  redirectVerifiedToHome,
  redirectUnverifiedToAuth,
  UpdateGuard,
} from '@clients/helper';
import { AdminGuard } from '@clients/api';

export const ROUTES: Route[] = [
  {
    path: '',
    canActivateChild: [UpdateGuard],
    children: [
      {
        path: 'auth',
        loadChildren: () =>
          import('@clients/auth').then((mod) => mod.AuthModule),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectVerifiedToHome },
      },
      {
        path: 'template',
        loadChildren: () =>
          import('@clients/edit').then((mod) => mod.EditModule),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnverifiedToAuth },
      },
      {
        path: 'generate/:templateId',
        loadChildren: () =>
          import('@clients/generate').then((mod) => mod.GenerateModule),
      },
      {
        path: 'membership',
        loadChildren: () =>
          import('@clients/membership').then((mod) => mod.MembershipModule),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnverifiedToAuth },
      },
      {
        path: 'guest-notes',
        loadChildren: () =>
          import('@clients/guest-notes').then((mod) => mod.GuestNotesModule),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnverifiedToAuth },
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('@clients/admin').then((mod) => mod.AdminModule),
        canActivate: [AngularFireAuthGuard, AdminGuard],
        data: {
          authGuardPipe: redirectUnverifiedToAuth,
          adminGuardRedirect: ['home'],
        },
      },
      {
        path: 'home',
        component: HomeComponent,
      },
      { path: '**', redirectTo: 'home' },
    ],
  },
];
