import { Component, EventEmitter, Inject, OnDestroy } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  LocalizedName,
  TemplateControllerService,
  TemplateDetailRead,
  TemplateListRead,
  VarTemplate,
  WhoAmI,
} from '@clients/api';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { MatOptionSelectionChange } from '@angular/material/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NewTemplateDialogComponent } from './new-template-dialog/new-template-dialog.component';
import { VarPlaceholderData } from './var-placeholder.data';

function templateIdValidator(templateList: TemplateListRead[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const template: TemplateListRead | undefined = templateList.find(
      (temp: TemplateListRead) => temp.template_id === control.value
    );

    return template ? null : { invalidSelection: true };
  };
}

@UntilDestroy()
@Component({
  selector: 'shared-var-placeholder',
  templateUrl: './var-placeholder.component.html',
  styleUrls: ['./var-placeholder.component.scss'],
})
export class VarPlaceholderComponent implements OnDestroy {
  form: FormGroup;
  localizedNamesControls: FormArray;
  templateList?: TemplateListRead[];
  filteredTemplates?: Observable<TemplateListRead[]>;
  localizationRequired?: boolean;

  private ngUnsubscribe?: Subject<void>;
  private nameRegex: RegExp = new RegExp(/^[^><\{\}\[\]\r\n\$\.]+$/);

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<
      VarPlaceholderComponent,
      VarPlaceholderData | null
    >,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type: VarTemplate.TypeEnum | null;
      current?: VarPlaceholderData;
      openTemplate: EventEmitter<number>;
      whoAmI: WhoAmI;
    },
    private templateControllerService: TemplateControllerService
  ) {
    this.localizedNamesControls = this.formBuilder.array([]);
    this.form = this.formBuilder.group({
      localized_names: this.localizedNamesControls,
      type: this.formBuilder.control(
        data.type ?? data.current?.type ?? VarTemplate.TypeEnum.Text,
        Validators.required
      ),
      is_optional: this.formBuilder.control(
        data.current ? !data.current.value.is_optional : true,
        Validators.required
      ),
      is_prefilled: this.formBuilder.control(
        data.current ? data.current.value.is_prefilled : false,
        Validators.required
      ),
    });

    this.templateControllerService
      .getMyTemplateListUsingGET({})
      .pipe(untilDestroyed(this))
      .subscribe((templateList: TemplateListRead[]) => {
        this.setTemplateList(templateList);
        this.updateTemplateControl();
      });

    if (!this.data.whoAmI?.is_template_user) {
      this.form.addControl(
        'name',
        this.formBuilder.control(
          data.current ? data.current.value.name : null,
          Validators.compose([
            Validators.required,
            Validators.pattern(this.nameRegex),
          ])
        )
      );
    } else {
      ['de', 'it', 'en'].forEach((langKey: string) => {
        const currentLocalized:
          | LocalizedName
          | undefined = this.data.current?.value.localized_names?.find(
          (localisation: LocalizedName) => localisation.language === langKey
        );
        this.localizedNamesControls.push(
          this.formBuilder.group({
            language: this.formBuilder.control(langKey),
            text: this.formBuilder.control(
              currentLocalized?.text,
              Validators.pattern(this.nameRegex)
            ),
          })
        );
      });

      if (!this.hasTranslation() && this.data.current?.value.name) {
        const group:
          | FormGroup
          | undefined = this.localizedNamesControls.controls.find(
          (control) => control.value.language === this.data.whoAmI.language
        ) as FormGroup;
        group?.controls['text'].setValue(this.data.current?.value.name);
      }
    }
  }

  private setTemplateList(templateList: TemplateListRead[]) {
    this.templateList = templateList;
    this.setTemplateControlValidators();
    this.startFiltering();
  }

  ngOnDestroy() {
    this.stopFiltering();
  }

  updateTemplateControl() {
    if (this.form.value.type === VarTemplate.TypeEnum.Template) {
      this.form.addControl(
        'template_id',
        this.formBuilder.control(
          this.data.current
            ? (this.data.current.value as VarTemplate).template_id
            : null
        )
      );
      this.setTemplateControlValidators();
      this.startFiltering();
    } else {
      this.form.removeControl('template_id');
      this.stopFiltering();
    }
  }

  autocompleteClosed() {
    if (
      typeof this.form.controls['template_id'].value !== 'string' ||
      !this.templateList
    ) {
      return;
    }

    const template: TemplateListRead | undefined = this.templateList.find(
      (templ: TemplateListRead) =>
        templ.name === this.form.controls['template_id'].value
    );
    if (template) {
      this.form.controls['template_id'].setValue(template.template_id);
    }
  }

  private setTemplateControlValidators() {
    if (!this.form.controls['template_id']) {
      return;
    }

    this.form.controls['template_id'].setValidators(
      Validators.compose([
        Validators.required,
        this.templateList ? templateIdValidator(this.templateList) : null,
      ])
    );
  }

  private hasTranslation(): boolean {
    return (
      this.localizedNamesControls.value
        .map((langVal: { language: string; text: string }) => langVal.text)
        .filter((text: string) => !!text).length > 0
    );
  }

  submit() {
    if (this.data.whoAmI.is_template_user && !this.hasTranslation()) {
      this.localizationRequired = true;
      this.form.markAllAsTouched();
      return;
    }

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const value = this.form.value;
    const varType = value.type;
    value.is_optional = !value.is_optional;
    delete value.type;
    value.localized_names = value.localized_names.filter(
      (trans: LocalizedName) => trans.text
    );

    if (this.data.whoAmI?.is_template_user) {
      value.name = null;
    }

    const result: VarPlaceholderData = { type: varType, value };

    this.dialogRef.close(result);
  }

  displayFn = (templateId?: number): string | undefined => {
    if (!this.templateList || !templateId) {
      return undefined;
    }
    const template: TemplateListRead | undefined = this.templateList.find(
      (templ: TemplateListRead) => templ.template_id === templateId
    );

    return template ? template.name : undefined;
  };

  private stopFiltering() {
    if (this.ngUnsubscribe) {
      this.ngUnsubscribe.next();
      this.ngUnsubscribe.complete();
    }
  }

  private startFiltering() {
    if (!this.form.controls['template_id']) {
      return;
    }

    this.stopFiltering();

    this.ngUnsubscribe = new Subject<void>();

    this.filteredTemplates = this.form.controls[
      'template_id'
    ].valueChanges.pipe(
      untilDestroyed(this),
      takeUntil(this.ngUnsubscribe),
      startWith(''),
      map((value: string) => this._filter(`${value}`))
    );
  }

  private _filter(value: string): TemplateListRead[] {
    const filterValue = value.toLowerCase();

    return this.templateList
      ? this.templateList.filter((template: TemplateListRead) =>
          (template.name ? template.name : '')
            .toLowerCase()
            .includes(filterValue)
        )
      : [];
  }

  newTemplate(event: MatOptionSelectionChange) {
    if (!event.source.selected) {
      return;
    }

    const dialog: MatDialogRef<NewTemplateDialogComponent> = this.matDialog.open(
      NewTemplateDialogComponent,
      {
        minWidth: '40vw',
        hasBackdrop: false,
      }
    );

    dialog
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((result: string) => {
        if (!result) {
          return;
        }

        this.templateControllerService
          .addTemplateUsingPOST({
            template: { name: result, is_favorite: false },
          })
          .pipe(untilDestroyed(this))
          .subscribe((template: TemplateDetailRead) => {
            this.setTemplateList([...(this.templateList || []), template]);
            this.form.controls['template_id'].setValue(template.template_id);
          });
      });
  }
}
