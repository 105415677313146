import { NgModule, ModuleWithProviders } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DeviceTokenInterceptor } from './device-token.interceptor';

@NgModule({})
export class DeviceModule {
  static forRoot(): ModuleWithProviders<DeviceModule> {
    return {
      ngModule: DeviceModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: DeviceTokenInterceptor,
          multi: true,
        },
      ],
    };
  }
}
