export const QUILL_MODULES = {
  clipboard: {
    matchVisual: false,
  },
  'quill-image-compress': {
    imageType: 'image/png',
    quality: 1,
    maxWidth: 1024,
    maxHeight: 1024,
  },
};
