import { Injectable } from '@angular/core';
import {
  TemplateAdd,
  TemplateControllerService,
  AddTemplateUsingPOSTRequestParams,
  TemplateListRead,
  TemplateDetailRead,
  VarValueContainer,
  GeneratedText,
  GenerationControllerService,
  TemplateNoVarsRead,
  GetTemplateUsingGETRequestParams,
  CloneTemplatesAndSubtemplatesUsingPOSTRequestParams,
} from '../auto-gen';
import { Observable, of } from 'rxjs';
import { map, take, mergeMap, catchError } from 'rxjs/operators';
import { FirebaseService } from '@clients/helper';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  constructor(
    private firebaseService: FirebaseService,
    private templateControllerService: TemplateControllerService,
    private generationControllerService: GenerationControllerService
  ) {}

  getList(templatecategoryId?: number): Observable<TemplateListRead[]> {
    return this.firebaseService.user$.pipe(
      take(1),
      mergeMap((user: firebase.User | null) => {
        return !!user
          ? this.templateControllerService.getMyTemplateListUsingGET({})
          : this.templateControllerService.getPredefinedTemplateListUsingGET({
              templatecategoryId,
            });
      }),
      catchError(() => {
        return of([]);
      })
    );
  }

  get(templateId: number): Observable<TemplateNoVarsRead> {
    return this.templateControllerService.getTemplateUsingGET({
      templateId,
    } as GetTemplateUsingGETRequestParams);
  }

  getDetails(templateId: number): Observable<TemplateDetailRead> {
    return this.templateControllerService.getTemplateDetailsUsingGET({
      templateId,
    });
  }

  create(template: TemplateAdd): Observable<void> {
    return this.templateControllerService
      .addTemplateUsingPOST({
        template,
      } as AddTemplateUsingPOSTRequestParams)
      .pipe(map(() => {}));
  }

  edit(templateId: number, template: TemplateAdd): Observable<void> {
    return this.templateControllerService
      .updateTemplateUsingPATCH({ templateId, template })
      .pipe(map(() => {}));
  }

  generate(
    templateId: number,
    language: string,
    varValueContainer: VarValueContainer
  ): Observable<GeneratedText> {
    return this.generationControllerService.generateUsingPOST({
      templateId,
      language,
      varValueContainer,
    });
  }

  delete(templateId: number): Observable<void> {
    return this.templateControllerService.deleteTemplateUsingDELETE({
      templateId: templateId,
    });
  }

  getUserList(userId: number): Observable<TemplateListRead[]> {
    return this.templateControllerService.getUserTemplateListUsingGET({
      userId,
    });
  }

  copyUserTemplates(
    requestParameters: CloneTemplatesAndSubtemplatesUsingPOSTRequestParams
  ): Observable<void> {
    return this.templateControllerService.cloneTemplatesAndSubtemplatesUsingPOST(
      requestParameters
    );
  }
}
