<ng-container *transloco="let t; read: 'app'">
  <h1 mat-dialog-title>{{ t('home.login-required.title') }}</h1>

  <div mat-dialog-content>
    <p>{{ t('home.login-required.text') }}</p>
  </div>

  <div mat-dialog-actions>
    <div fxFlex="1 1 auto"></div>
    <button mat-stroked-button [mat-dialog-close]="false">
      {{ t('general.cancel') }}
    </button>
    <button
      mat-raised-button
      color="accent"
      [mat-dialog-close]="true"
      cdkFocusInitial
    >
      {{ t('home.login-required.login') }}
    </button>
  </div>
</ng-container>
