import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'shared-new-template-dialog',
  templateUrl: './new-template-dialog.component.html',
  styleUrls: ['./new-template-dialog.component.scss'],
})
export class NewTemplateDialogComponent {
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<NewTemplateDialogComponent>
  ) {
    this.form = this.formBuilder.group({
      name: this.formBuilder.control(
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(new RegExp(/^[^><,|:\?!\{\}\[\]\r\n\$\.=;]+$/)),
        ])
      ),
    });
  }

  submit() {
    if (this.form.invalid) {
      return;
    }

    this.dialogRef.close(this.form.controls['name'].value);
  }
}
