import { getBrowserLang, TranslocoConfig } from '@ngneat/transloco';

export const configurationFactory = (environment: {
  production: boolean;
}): TranslocoConfig => {
  return {
    reRenderOnLangChange: true,
    availableLangs: ['de', 'it', 'en'],
    defaultLang: getBrowserLang() || 'de',
    fallbackLang: 'de',
    missingHandler: {
      useFallbackTranslation: true,
    },
    prodMode: environment.production,
  };
};
