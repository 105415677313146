import { Injectable } from '@angular/core';
import { VarPlaceholderBlot } from './var-placeholder.blot';
import { VarPlaceholderData } from './var-placeholder.data';
import { Subject } from 'rxjs';
import QuillType, { RangeStatic } from 'quill';

export interface PlaceholderEvent {
  blot: VarPlaceholderBlot;
  data: VarPlaceholderData;
  type: 'dblclick';
}

@Injectable({
  providedIn: 'root',
})
export class VarPlaceholderService {
  static WINDOW_KEY_REFERENCE = 'GTG_VAR_PLACEHOLDER_SERVICE';

  events$: Subject<PlaceholderEvent> = new Subject<PlaceholderEvent>();

  constructor() {
    (window as any)[VarPlaceholderService.WINDOW_KEY_REFERENCE] = this;
  }

  ondblclick(blot: VarPlaceholderBlot, data: VarPlaceholderData) {
    this.events$.next({ blot, data, type: 'dblclick' });
  }

  getIndex(quill?: QuillType): RangeStatic | undefined {
    if (!quill) {
      return;
    }

    return quill.getSelection() ?? undefined;
  }

  insertEmbed(
    data: VarPlaceholderData,
    selection?: RangeStatic,
    quill?: QuillType
  ) {
    if (!quill) {
      return;
    }

    const range = selection ?? this.getIndex(quill);
    const position = (range && range.index) || 0;

    quill.insertEmbed(position, 'quill-gtg-var', data, 'user');
    quill.setSelection({
      index: position + 1,
      length: 0,
    });
  }
}
