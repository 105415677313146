import { Configuration } from '../auto-gen';
import { ApiEnvironment } from './api-environment';

export const configurationFactory = (
  environment: ApiEnvironment
): Configuration => {
  return new Configuration({
    basePath: environment.api,
  });
};
