<div
  *ngIf="loading; else content"
  class="full-height"
  fxLayout
  fxLayoutAlign="center center"
>
  <mat-spinner></mat-spinner>
</div>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
