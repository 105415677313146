<mat-card
  *transloco="let t; read: 'app.membership'"
  class="secondary-theme"
  [ngClass.gt-sm]="'small'"
>
  <mat-card-content
    fxLayout="column"
    fxLayoutAlign="start center"
    fxLayoutGap="8px"
  >
    <span class="text" style="color: white">{{ t('text1') }}</span>
    <span class="text">{{ t('text2') }}</span>
    <span class="text">{{ t('text3') }}</span>
    <span class="value">{{ t('value') }}</span>

    <div fxFlex="1 1 auto"></div>

    <button
      mat-raised-button
      class="large-font"
      color="secondary-accent"
      [routerLink]="['/', 'membership']"
      queryParamsHandling="merge"
    >
      {{ t('action') }}
    </button>
  </mat-card-content>
</mat-card>
