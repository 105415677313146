import { Pipe, PipeTransform } from '@angular/core';
import { TemplateListRead } from '@clients/api';

@Pipe({
  name: 'filter',
  pure: true,
})
export class FilterPipe implements PipeTransform {
  transform(
    templates: TemplateListRead[] = [],
    filter: string = ''
  ): TemplateListRead[] {
    return templates.filter(
      (template: TemplateListRead) =>
        template.name &&
        template.name.toLowerCase().includes(filter.toLowerCase())
    );
  }
}
