<div class="wrapper" fxLayout fxLayoutAlign="space-evently">
  <button
    mat-stroked-button
    *ngFor="let lang of available"
    fxLayout
    fxLayoutAlign="center"
    (click)="selectionChanged.emit(lang)"
    [ngClass]="{ selected: lang === selected }"
    [disabled]="lang === selected"
    type="button"
  >
    <mat-icon [svgIcon]="lang"></mat-icon>
  </button>
</div>
