import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { TemplateListRead, TemplatePosition } from '@clients/api';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'clients-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateListComponent {
  private readonly COLLAPSED_QUERY_PARAM = 'collapsed';
  private readonly FILTER_QUERY_PARAM = 'filter';

  @Input() set templates(templates: TemplateListRead[]) {
    this.allTemplates = templates;
    this.oddTemplates = templates.filter(
      (template: TemplateListRead) => (template.position || 0) % 2 !== 0
    );
    this.evenTemplates = templates.filter(
      (template: TemplateListRead) => (template.position || 0) % 2 === 0
    );
  }
  allTemplates?: TemplateListRead[];
  oddTemplates?: TemplateListRead[];
  evenTemplates?: TemplateListRead[];

  @Input() title?: string;
  @Input() set collapsable(collapsable: boolean) {
    this._collapsable = collapsable;
    if (!this._collapsable) {
      return;
    }

    this.activatedRoute.queryParamMap
      .pipe(take(1))
      .subscribe((params: ParamMap) => {
        if (params.has(this.COLLAPSED_QUERY_PARAM)) {
          this.collapsed = JSON.parse(
            params.get(this.COLLAPSED_QUERY_PARAM) ?? ''
          );
        }
      });
  }
  _collapsable = false;
  @Input() set allowFilter(allowFilter: boolean) {
    this._allowFilter = allowFilter;
    if (!this._allowFilter) {
      return;
    }

    this.activatedRoute.queryParamMap
      .pipe(take(1))
      .subscribe((params: ParamMap) => {
        if (params.has(this.FILTER_QUERY_PARAM)) {
          this.filter = params.get(this.FILTER_QUERY_PARAM) ?? '';
        }
      });
  }
  _allowFilter = false;
  @Input() allowSorting = false;

  @Output() updatePositions: EventEmitter<
    TemplatePosition[]
  > = new EventEmitter<TemplatePosition[]>();
  @Output() generateEmail: EventEmitter<number> = new EventEmitter<number>();
  @Output() editTemplate: EventEmitter<number> = new EventEmitter<number>();
  @Output() newTemplate: EventEmitter<void> = new EventEmitter<void>();

  collapsed = true;
  filter = '';

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  private updateParams() {
    const queryParams: { [key: string]: string | undefined } = {};
    queryParams[this.COLLAPSED_QUERY_PARAM] = this.collapsed
      ? undefined
      : this.collapsed.toString();
    queryParams[this.FILTER_QUERY_PARAM] =
      this.filter?.length > 0 ? this.filter : undefined;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  toggleCollapsed() {
    this.collapsed = !this.collapsed;
    this.updateParams();
  }

  setFilter(value: string) {
    this.filter = value;
    this.updateParams();
  }

  drop(event: CdkDragDrop<TemplateListRead[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    if (this.oddTemplates) {
      this.oddTemplates = [...this.oddTemplates];
    }
    if (this.evenTemplates) {
      this.evenTemplates = [...this.evenTemplates];
    }

    this._updatePositions();
  }

  private _updatePositions() {
    const positions: TemplatePosition[] = [];

    if (this.oddTemplates) {
      this.oddTemplates.forEach((template: TemplateListRead, index: number) => {
        positions.push({
          template_id: template.template_id,
          position: -1 - index * 2,
        });
      });
    }
    if (this.evenTemplates) {
      this.evenTemplates.forEach(
        (template: TemplateListRead, index: number) => {
          positions.push({
            template_id: template.template_id,
            position: -2 - index * 2,
          });
        }
      );
    }

    this.updatePositions.emit(positions);
  }
}
