<div *transloco="let t" fxLayout="column" fxLayoutGap="8px">
  <button mat-raised-button *ngIf="_collapsable" (click)="toggleCollapsed()">
    <div fxLayout fxLayoutAlign="space-between center" fxLayoutGap="16px">
      <span *ngIf="title">{{ title }}</span>

      <span>
        {{ collapsed ? '&#9660;' : '&#9650;' }}
      </span>
    </div>
  </button>

  <ng-container *ngIf="!_collapsable || (_collapsable && !collapsed)">
    <mat-form-field *ngIf="_allowFilter">
      <button mat-button matPrefix mat-icon-button>
        <mat-icon>search</mat-icon>
      </button>
      <input
        *transloco="let t"
        [helperAutofocus]="true"
        matInput
        type="text"
        [placeholder]="t('app.home.template-list.search')"
        [ngModel]="filter"
        (ngModelChange)="setFilter($event)"
      />
      <button
        mat-button
        *ngIf="filter"
        matSuffix
        mat-icon-button
        (click)="setFilter('')"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>

    <div
      gdColumns="1fr 1fr"
      gdColumns.lt-md="1fr"
      gdGap="16px"
      gdGap.lt-md="8px"
      cdkDropListGroup
    >
      <ng-container *ngIf="allowSorting; else alphabetically">
        <div
          cdkDropList
          [cdkDropListData]="oddTemplates"
          (cdkDropListDropped)="drop($event)"
          fxLayout="column"
          fxLayoutGap="16px"
          fxLayoutGap.lt-md="8px"
        >
          <clients-template-card
            *ngFor="let template of oddTemplates | filter: filter"
            cdkDrag
            [template]="template"
            (generate)="generateEmail.emit(template.template_id)"
            (edit)="editTemplate.emit(template.template_id)"
          >
            <div class="custom-placeholder" *cdkDragPlaceholder></div>
            <button
              mat-button
              cdkDragHandle
              class="drag-handle"
              [matTooltip]="t('app.home.template-list.move')"
            >
              <mat-icon>drag_indicator</mat-icon>
            </button>
          </clients-template-card>
          <div
            *ngIf="oddTemplates?.length === 0"
            [fxHide.gt-sm]="true"
            class="empty-list-placeholder"
          ></div>
        </div>

        <mat-divider [fxHide.gt-sm]="true"></mat-divider>

        <div
          cdkDropList
          [cdkDropListData]="evenTemplates"
          (cdkDropListDropped)="drop($event)"
          fxLayout="column"
          fxLayoutGap="16px"
          fxLayoutGap.lt-md="8px"
        >
          <clients-template-card
            *ngFor="let template of evenTemplates | filter: filter"
            cdkDrag
            [template]="template"
            (generate)="generateEmail.emit(template.template_id)"
            (edit)="editTemplate.emit(template.template_id)"
          >
            <div class="custom-placeholder" *cdkDragPlaceholder></div>
            <button mat-button cdkDragHandle class="drag-handle">
              <mat-icon> drag_indicator </mat-icon>
            </button>
          </clients-template-card>
          <div
            *ngIf="evenTemplates?.length === 0"
            [fxHide.gt-sm]="true"
            class="empty-list-placeholder"
          ></div>
        </div>
      </ng-container>

      <ng-template #alphabetically>
        <clients-template-card
          *ngFor="let template of allTemplates | filter: filter"
          [template]="template"
          (generate)="generateEmail.emit(template.template_id)"
          (edit)="editTemplate.emit(template.template_id)"
        ></clients-template-card>
      </ng-template>

      <clients-new-template-card
        gdArea="auto / span 2"
        gdArea.lt-md="auto / auto"
        (new)="newTemplate.emit()"
      ></clients-new-template-card>
    </div>
  </ng-container>
</div>
