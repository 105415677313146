<div class="full-height" gdRows="auto minmax(0, 1fr)">
  <mat-toolbar
    color="primary"
    *transloco="let t; read: 'app'"
    fxLayout
    fxLayoutGap="8px"
  >
    <button
      mat-icon-button
      [routerLink]="returnRoute"
      [routerLinkActive]="['hidden']"
      [routerLinkActiveOptions]="{ exact: true }"
      queryParamsHandling="merge"
    >
      <mat-icon>arrow_back</mat-icon>
    </button>

    <span fxFlex="1 1 auto" class="title" *ngIf="title; else appTitle">
      {{ title }}
    </span>
    <ng-template #appTitle>
      <img
        fxHide.xs
        src="assets/logo_get-the-guest_rgb_trans_250x40.png"
        alt="Get the Guest"
      />
      <img
        fxHide.gt-xs
        src="assets/logo_get-the-guest_rgb_trans_40x40.png"
        alt="Get the Guest"
      />
    </ng-template>

    <div fxFlex="1 1 auto"></div>

    <clients-user
      [user]="user"
      [whoAmI]="whoAmI"
      [availableLangs]="availableLangs"
      [currentLang]="currentLang"
      [membership]="membership"
      (changeLang)="changeLang($event)"
    ></clients-user>
  </mat-toolbar>

  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>
