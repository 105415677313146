import Quill from 'quill';
import { default as ParchmentTypes } from 'parchment';
import { VarPlaceholderData } from './var-placeholder.data';
import { VarPlaceholderService } from './var-placeholder.service';
import { VarDate, VarTemplate, VarText } from '@clients/api';
const Embed: typeof ParchmentTypes.Embed = Quill.import('blots/embed');

export class VarPlaceholderBlot extends Embed {
  static updateFormatKey = 'gtg-var-format';
  static blotName = 'quill-gtg-var';
  static className = 'gtg-var';
  static tagName = 'img';
  static iconIdPrefix = 'var-placeholder-';

  static create(value: VarPlaceholderData) {
    const node: HTMLElement = <HTMLElement>super.create(value);
    return VarPlaceholderBlot.adjustNode(node, value);
  }

  // @ts-ignore
  static value(domNode: HTMLElement): VarPlaceholderData | null {
    const type: VarTemplate.TypeEnum | null = domNode.dataset
      .type as VarTemplate.TypeEnum;
    const value = domNode.dataset.value;
    if (!type || !value) {
      return null;
    }
    switch (type) {
      case VarTemplate.TypeEnum.Text:
        return { type, value: JSON.parse(value) as VarText };
      case VarTemplate.TypeEnum.Date:
        return { type, value: JSON.parse(value) as VarDate };
      case VarTemplate.TypeEnum.Template:
        return { type, value: JSON.parse(value) as VarTemplate };
    }
  }

  static adjustNode(node: HTMLElement, data: VarPlaceholderData): HTMLElement {
    node.dataset.type = data.type;
    node.dataset.value = JSON.stringify(data.value);
    node.draggable = false;

    const img: HTMLImageElement | null = document.getElementById(
      VarPlaceholderBlot.iconIdPrefix + data.type
    ) as HTMLImageElement;
    if (img) {
      if (img.complete) {
        VarPlaceholderBlot.drawCanvas(node, data, img);
      } else {
        img.addEventListener('load', () => {
          VarPlaceholderBlot.drawCanvas(node, data, img);
        });
      }
    }

    VarPlaceholderBlot.attachClickListener(node);
    return node;
  }

  static drawCanvas(
    node: HTMLElement,
    data: VarPlaceholderData,
    img: HTMLImageElement
  ) {
    const canvasHeight = 18;
    const contentSize = 14;
    const padding = 5;

    const canvas: HTMLCanvasElement = document.createElement('canvas');
    canvas.height = canvasHeight;
    const ctx: CanvasRenderingContext2D | null = canvas?.getContext('2d');
    const font = `normal ${contentSize}px sans-serif`;
    const text =
      data.value.name ??
      (data.value.localized_names ? data.value.localized_names[0]?.text : '') ??
      '';

    if (ctx) {
      ctx.font = font;
      canvas.width =
        padding +
        contentSize +
        padding +
        Math.round(ctx.measureText(text).width) +
        padding;
      ctx.font = font; // has to be reset due to changing canvas size resetting everything
      ctx.drawImage(
        img,
        padding,
        (canvasHeight - contentSize) / 2,
        contentSize,
        contentSize
      );
      ctx.fillText(
        text,
        padding + contentSize + padding,
        canvasHeight - (canvasHeight - contentSize)
      );
      const image: string = canvas.toDataURL();
      node.setAttribute('src', image);
    }
  }

  static attachClickListener(node: HTMLElement) {
    node.ondblclick = () => {
      const blot: VarPlaceholderBlot | null = ParchmentTypes.find(
        node
      ) as VarPlaceholderBlot;
      if (blot) {
        const data: VarPlaceholderData | null = VarPlaceholderBlot.value(
          blot.domNode as HTMLElement
        );
        if (data) {
          const varPlaceholderService: VarPlaceholderService = <
            VarPlaceholderService
          >(window as any)[VarPlaceholderService.WINDOW_KEY_REFERENCE];

          if (varPlaceholderService) {
            varPlaceholderService.ondblclick(blot, data);
          }
        }
      }
    };
  }

  // attach() {
  //   // console.log(this.domNode)
  //   super.attach();
  //   VarPlaceholderBlot.attachClickListener(<HTMLElement>this.domNode);
  //
  //   const blot: VarPlaceholderBlot | null = ParchmentTypes.find(
  //     this.domNode
  //   ) as VarPlaceholderBlot;
  //   if (blot) {
  //     const data: VarPlaceholderData | null = VarPlaceholderBlot.value(<HTMLElement>this.domNode)
  //     if (data) {
  //       VarPlaceholderBlot.adjustNode(<HTMLElement>this.domNode, data);
  //       // blot.replaceWith(VarPlaceholderBlot.name, data)
  //       // blot.remove()
  //       // console.log(data)
  //     }
  //   }
  // };

  format(name: string, value: any): void {
    if (name === VarPlaceholderBlot.updateFormatKey) {
      this.domNode = VarPlaceholderBlot.adjustNode(
        this.domNode as HTMLElement,
        value
      );
    } else {
      super.format(name, value);
    }
  }
}
