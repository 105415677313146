import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoginControllerService, WhoAmI } from '../auto-gen';
import { publishReplay, refCount } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class WhoAmIService {
  whoAmI$: BehaviorSubject<WhoAmI | undefined>;

  private whoAmIRequest$?: Observable<WhoAmI>;

  constructor(private loginControllerService: LoginControllerService) {
    this.whoAmI$ = new BehaviorSubject<WhoAmI | undefined>(undefined);
  }

  reset() {
    this.whoAmI$.next(undefined);
    this.whoAmIRequest$ = undefined;
  }

  refresh(force: boolean = false) {
    if (!this.whoAmIRequest$ || force) {
      this.whoAmIRequest$ = this.loginControllerService
        .getWhoIAmUsingGET({})
        .pipe(
          untilDestroyed(this),
          publishReplay(1), // this tells Rx to cache the latest emitted
          refCount()
        );
    }

    this.whoAmIRequest$.subscribe((whoAmI: WhoAmI) =>
      this.whoAmI$.next(whoAmI)
    );
  }
}
