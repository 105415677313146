<mat-card *transloco="let t">
  <mat-card-content fxLayout fxLayoutAlign="start center">
    <ng-content></ng-content>
    <button
      mat-button
      fxFlex="1 1 auto"
      class="name-button"
      (click)="generate.emit()"
    >
      {{ template?.name }}
    </button>
    <mat-divider [vertical]="true"></mat-divider>
    <button
      mat-icon-button
      class="edit-button"
      (click)="edit.emit()"
      [matTooltip]="t('app.general.edit')"
    >
      <mat-icon>edit</mat-icon>
    </button>
  </mat-card-content>
</mat-card>
