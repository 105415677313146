import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Membership, MySettingsControllerService } from '../auto-gen';
import { publishReplay, refCount } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class MembershipService {
  membership$: BehaviorSubject<Membership | undefined>;

  private membershipRequest$?: Observable<Membership>;

  constructor(
    private mySettingsControllerService: MySettingsControllerService
  ) {
    this.membership$ = new BehaviorSubject<Membership | undefined>(undefined);
  }

  reset() {
    this.membership$.next(undefined);
    this.membershipRequest$ = undefined;
  }

  refresh() {
    if (!this.membershipRequest$) {
      this.membershipRequest$ = this.mySettingsControllerService
        .getMyMembershipUsingGET({})
        .pipe(
          untilDestroyed(this),
          publishReplay(1), // this tells Rx to cache the latest emitted
          refCount()
        );
    }

    this.membershipRequest$.subscribe((membership: Membership) =>
      this.membership$.next(membership)
    );
  }
}
