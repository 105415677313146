<div
  class="max-width full-height"
  [gdRows]="
    license?.is_time_over ||
    license?.is_expiring_soon ||
    templateCategoryControl
      ? 'auto 1fr'
      : '1fr'
  "
  gdGap="16px"
  *transloco="let t"
>
  <clients-license-exipred
    *ngIf="license?.is_time_over"
  ></clients-license-exipred>
  <clients-license-exipres-soon
    *ngIf="!license?.is_time_over && license?.is_expiring_soon"
  ></clients-license-exipres-soon>

  <mat-form-field *ngIf="templateCategoryControl" class="full-width">
    <mat-label>{{ t('app.home.templatecategory') }}</mat-label>
    <mat-select
      [formControl]="templateCategoryControl"
      (selectionChange)="getTemplateList()"
    >
      <mat-option
        *ngFor="let option of templateCategories"
        [value]="option.templatecategory_id"
      >
        {{ option.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <shared-loading-spinner [loading]="loading">
    <div class="max-width" fxLayout="column" fxLayoutGap="16px">
      <clients-template-list
        [templates]="favorites"
        [allowSorting]="!!user"
        (generateEmail)="generateTemplate($event)"
        (editTemplate)="editTemplate($event)"
        (newTemplate)="newTemplate(true)"
        (updatePositions)="updatePositions($event)"
      ></clients-template-list>

      <clients-recommend *ngIf="membership?.coupon"></clients-recommend>

      <mat-divider *ngIf="noneFavorites?.length > 0"></mat-divider>

      <clients-template-list
        *ngIf="noneFavorites?.length > 0"
        [templates]="noneFavorites"
        [title]="t('app.home.templates')"
        [collapsable]="true"
        [allowFilter]="true"
        (generateEmail)="generateTemplate($event)"
        (editTemplate)="editTemplate($event)"
        (newTemplate)="newTemplate(false)"
      ></clients-template-list>
    </div>
  </shared-loading-spinner>
</div>
