import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotImplementedDirective } from './not-implemented.directive';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslationModule } from '../translation/translation.module';

@NgModule({
  declarations: [NotImplementedDirective],
  imports: [CommonModule, MatSnackBarModule, TranslationModule],
  exports: [NotImplementedDirective],
})
export class NotImplementedModule {}
